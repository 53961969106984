<template >
  <div>
    <v-form v-model="valid" ref="postArticleForm">
      <v-container>
        <h1>{{msg}}</h1>
        <v-row>
          <v-col cols="7">
            <h3>Informations</h3>
            <v-text-field
              v-model="label"
              :rules="labelRules"
              :counter="90"
              label="Titre de l'article"
              hint="ne doit pas dépasser 32 characteres"
              required
            ></v-text-field>
            <v-text-field
              disabled
              v-model="url"
              :rules="urlRules"
              :counter="90"
              label="URL de l'article"
              hint="L'URL doit être unique et sera validée au moment de la soumission"
              required
            ></v-text-field>
            <v-text-field
              v-model="picture"
              label="Vignette"
              hint="entrez une URL valide les 400(*) ne sont pas acceptées"
            ></v-text-field>
            <v-textarea
              auto-grow
              v-model="short_description"
              :rules="shortRules"
              :counter="256"
              label="Description courte"
              required
              hint="votre description ne doit pas dépasser 256 charactères."
            ></v-textarea>
            <v-textarea
              auto-grow
              v-model="intro"
              :rules="introRules"
              :counter="1540"
              label="Introduction"
              hint="Si ce champ n'est pas rempli l'arrticle utilisera la desciption courte 480 charactères."
            ></v-textarea>
            <v-select
              :rules="categoryRules"
              v-model="category"
              :items="categories"
              label="Categorie"
            ></v-select>
            <v-text-field
              auto-grow
              v-model="tags"
              :rules="tagsRules"
              label="Tags"
              hint="Entrez des mots clés séparés par une virgule"
            ></v-text-field>
            <v-row>
              <v-chip class="ma-2" v-for="tag in tagsArray" :key="tag">{{tag}}</v-chip>
            </v-row>
            <br />

            <v-switch v-model="publicated" label="Publier"></v-switch>
            <v-switch required v-model="hasMarkdown" label="Afficher l'éditeur Markdown"></v-switch>
          </v-col>
          <v-col cols="5">
            <h3>Vignette prévue</h3>
            <br />
            <v-card flat style="padding:14px;" max-width="300" elevation="12">
              <v-img
                v-if="picture.indexOf('http') !== -1"
                :src="picture"
                max-width="300"
                height="200"
                style="background-color:#e8ebf7;"
              />
              <Alphabet
                :message="picture"
                :width="272"
                :height="450"
                v-if="picture.indexOf('http') === -1"
              ></Alphabet>
            </v-card>
            <br />
            <h3>Date de publication</h3>
            <br />
            <v-date-picker v-model="publication" :rules="publicationRules"></v-date-picker>
          </v-col>
        </v-row>
      </v-container>
      <editor :markdown="hasMarkdown" :input="markdown" v-on:updateInput="setInput"></editor>
      <div class="fixedBottom">
        <v-container>
          <v-btn :disabled="!valid" class="primary" v-on:click="saveArticle">Enregistrer</v-btn>&nbsp;
          <!-- <v-btn
            v-if="$route.params.id"
            class="danger"
            v-on:click="deleteArticle"
          >Supprimer cet article</v-btn>-->
          <v-dialog v-model="dialog" persistent max-width="290">
            <template v-slot:activator="{ on }">
              <v-btn color="primary" dark v-on="on">Supprimer</v-btn>
            </template>
            <v-card>
              <v-card-title class="headline">Souhaitez-vous supprimer cet article?</v-card-title>
              <v-card-text>Cette action est irrémédiable, si vous acceptez, l'article sera définitivement supprimé et vous ne pourrez plus le retroouver</v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="dialog = false">Annuler</v-btn>
                <v-btn color="red" text @click="deleteArticle">Supprimer</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-container>
      </div>
    </v-form>
  </div>
</template>


<script>
import editor from "@/components/ContentEditor/MarkDown";
import Axios from "axios";
import Alphabet from "@/components/Articles/alphabet.vue";

const config = require("../credentials/ENV/prod");
var baseURL = config.API.base_url;
if (process.env.NODE_ENV === "development") {
  baseURL = config.API.base_url_locale;
}
const API = Axios.create({
  baseURL: baseURL, // SO :  swicth to '../credentials/ENV/prod' for local debug
  timeout: 10000,
  headers: {
    "Accept-Language": "fr-FR" // TODO switch User accept langages ESP belgium NL FR ...
  }
});

export default {
  name: "HelloWorld",
  components: { editor, Alphabet },
  props: ["msg"],
  data: () => ({
    dialog: false,
    categories: ["Technology", "UIUX", "Integration", "Divers"],
    valid: true,
    publicated: false,
    hasMarkdown: true,
    left: 0,
    labelRules: [
      v => v.length >= 10 || "le titre doit compter plus de 10 charactéres",
      v => v.length <= 90 || "Name must be less than 10 characters"
    ],
    urlRules: [
      v => !!v || "Label is required",
      v => v.length <= 90 || "Name must be less than 10 characters"
    ],
    pictureRules: [v => !!v || "Label is required"],
    tagsRules: [
      v => v.length >= 3 || "Vous devez entrer au moins un tag de 3 charactères"
    ],
    shortRules: [
      v =>
        v.length >= 25 ||
        "la description courte doit contenir plus de 25 charactéres",
      v => v.length <= 256 || "Name must be less than 256 characters"
    ],
    introRules: [
      v => v.length <= 1540 || "Name must be less than 10 characters"
    ],
    categoryRules: [v => !!v || "pour publier, choisis une catégorie"],
    publicationRules: [v => !!v || "need to pick a date"],
    label: "",
    picture: "",
    short_description: "",
    intro: "",
    category: "",
    publication: new Date().toISOString().substr(0, 10),
    tags: [],
    markdown: "",
    tagsArray: []
  }),
  async mounted() {
    if (this.$route.params.id) {
      let articles = await API.get(`/articles/${this.$route.params.id}`);
      Object.keys(articles.data.data).forEach(
        function(key) {
          if (key === "tags")
            articles.data.data[key] = articles.data.data[key].join(",");
          if (key !== "url") this[key] = articles.data.data[key];
        }.bind(this)
      );
    }
  },
  methods: {
    setInput: function(value) {
      this.markdown = value;
    },
    saveArticle: function() {
      if (!this.$route.params.id) {
        this.$store.commit("postArticle", {
          label: this.label,
          url: this.url,
          picture: this.picture,
          short_description: this.short_description,
          intro: this.intro,
          markdown: this.markdown,
          category: this.category.toLowerCase(),
          tags: this.tags.split(","),
          publicated: this.publicated,
          publication: this.publication
        });
        // window.location.href = "/feeds/" + this.url;
      } else {
        this.$store.commit("patchArticle", {
          label: this.label,
          url: this.url,
          picture: this.picture,
          short_description: this.short_description,
          intro: this.intro,
          markdown: this.markdown,
          category: this.category.toLowerCase(),
          tags: this.tags.split(","),
          publicated: this.publicated,
          publication: this.publication
        });
      }
    },
    deleteArticle: function() {
      this.dialog = false;
      this.$store.commit("deleteArticle");
    }
  },
  computed: {
    url() {
      let str = this.label
        .toLowerCase()
        .replace(/[àáâãäå]/g, "a")
        .replace(/[éèëê]/g, "e")
        .replace(/[ïîì]/g, "i")
        .replace(/[òöôoõ]/g, "o")
        .replace(/[üûù]/g, "u")
        .replace(/[ñ]/g, "n")
        .replace(/[ç]/g, "c")
        .replace(/[ ]/g, "-");
      return str.replace(/[^a-z0-9/-]/gi, "");
    }
  },
  watch: {
    tags: function() {
      this.tagsArray = this.tags.split(",");
    }
  }
};
</script>