<template>
  <div class="home">
    <!-- <img alt="Bige logo" :src="logoBlue" /> -->
    <ArticleForm msg="Partager un article." />
  </div>
</template>

<script>
// @ is an alias to /src
import ArticleForm from "@/components/articleForm.vue";
export default {
  name: "Home",
  components: {
    ArticleForm
  },
  data() {
    return {};
  }
};
</script>