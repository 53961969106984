<template>
  <div>
    <v-row style="padding-bottom: 63px; ">
      <v-col cols="6" v-if="markdown" style="background-color:#e9ebf7;">
        <v-row style="padding-left:20px">
          <v-icon>mdi-language-markdown-outline</v-icon>
          <v-icon>mdi-language-html5</v-icon>
          <h3 style="padding-left:20px">Markdown & html mixin</h3>
        </v-row>
      </v-col>
      <v-col cols="6" v-if="markdown" style="background-color:#e9ebf7;">
        <v-row>
          <v-icon>mdi-eye</v-icon>
          <h3 style="padding-left:20px">Preview</h3>
        </v-row>
      </v-col>
      <v-col cols="12" sm="6" class="MarkDownArea" v-if="markdown">
        <v-row style="margin-bottom: 10px;">
          <v-icon
            medium
            style="margin-left: 10px;"
            ripple
            color="primary"
            @click="addContent('title')"
          >mdi-format-title</v-icon>
          <v-icon
            medium
            style="margin-left: 10px;"
            ripple
            color="primary"
            @click="addContent('paragraph')"
          >mdi-text-subject</v-icon>
          <v-icon
            medium
            style="margin-left: 10px;"
            ripple
            color="primary"
            @click="addContent('image')"
          >mdi-file-image-outline</v-icon>
          <v-icon
            medium
            style="margin-left: 10px;"
            ripple
            color="primary"
            @click="addContent('list')"
          >mdi-format-list-bulleted</v-icon>
          <v-icon
            medium
            style="margin-left: 10px;"
            ripple
            color="primary"
            @click="addContent('columns')"
          >mdi-format-columns</v-icon>
          <v-icon
            medium
            style="margin-left: 10px;"
            ripple
            color="primary"
            @click="addContent('container')"
          >mdi-format-float-center</v-icon>
          <v-icon
            medium
            style="margin-left: 10px;"
            ripple
            color="primary"
            @click="addContent('iframe')"
          >mdi-iframe</v-icon>
          <v-icon
            medium
            style="margin-left: 10px;"
            ripple
            color="primary"
            @click="addContent('animation')"
          >mdi-animation</v-icon>
          <v-icon
            medium
            style="margin-left: 10px;"
            ripple
            color="primary"
            @click="addContent('javascript')"
          >mdi-language-javascript</v-icon>
          <v-icon
            medium
            style="margin-left: 10px;"
            ripple
            color="primary"
            @click="addContent('css')"
          >mdi-language-css3</v-icon>
          <v-icon
            medium
            style="margin-left: 10px;"
            ripple
            color="primary"
            @click="addContent('html')"
          >mdi-language-html</v-icon>
          <v-icon
            medium
            style="margin-left: 10px;"
            ripple
            color="primary"
            @click="addContent('python')"
          >mdi-language-python</v-icon>
          <v-icon
            medium
            style="margin-left: 10px;"
            ripple
            color="primary"
            @click="addContent('ruby')"
          >mdi-language-ruby</v-icon>
          <v-icon
            medium
            style="margin-left: 10px;"
            ripple
            color="primary"
            @click="addContent('php')"
          >mdi-language-php</v-icon>
        </v-row>
        <v-textarea
          auto-grow
          solo
          filled
          :value="input"
          @input="update"
          class="MarkDownTextArea"
          label="Ajoutez du contenu Markdown"
        ></v-textarea>
        <div style="height:1400px;"></div>
      </v-col>
      <v-col :cols="markdown ? 6 : 12">
        <div v-html="compiledMarkdown" class="MarkDownPreview" style="padding-bottom:4000px;"></div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import marked from "marked";
// import _ from "lodash";

import hljs from "highlight.js/lib/highlight";
import javascript from "highlight.js/lib/languages/javascript";
import "@/assets/sass/hljs-custom.scss";
hljs.registerLanguage("javascript", javascript);

export default {
  name: "MarkDown",
  components: {},
  props: {
    input: {
      type: String,
      required: true
    },
    markdown: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    // markdown: true
  }),
  computed: {
    compiledMarkdown: function() {
      return marked(this.input, {});
    }
  },
  methods: {
    update: function(value) {
      // this.input = value;
      this.$emit("updateInput", value);

      setTimeout(function() {
        document.querySelectorAll("pre code").forEach(block => {
          // block.classList.add("javascript");
          hljs.highlightBlock(block);
        });
      }, 100);
    },
    addContent: function(type) {
      switch (type) {
        case "title":
          this.$emit("updateInput", `${this.input}\n# Sample title`);
          break;
        case "paragraph":
          this.$emit(
            "updateInput",
            `${this.input}"\n> this is a paragraph sample (...) break each line by chevron >`
          );
          break;
        case "image":
          this.$emit(
            "updateInput",
            `${this.input}\n![image](https://dwglogo.com/wp-content/uploads/2017/09/Vue-logo-001.svg "logo vuejs")`
          );
          break;
        case "list":
          this.$emit(
            "updateInput",
            `${this.input}\n1. Number item\n2. Another number item\n  1. Sub item number.\n* bullet sample\n  * sublist bullet sample`
          );
          break;
        case "iframe":
          this.$emit(
            "updateInput",
            `${this.input}\n\n<iframe width="560" height="315" src="https://www.youtube.com/embed/S4IOxYx2Qq4" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
`
          );
          break;
        case "javascript":
          this.$emit(
            "updateInput",
            this.input +
              "\n```javascript\nlet sayHello = () => {\n    return 'hello';\n}\n```"
          );
          break;
        case "animation":
          this.$emit(
            "updateInput",
            `${this.input}\n[motion](path://to-your-lottie-json-file.json, loop:Boolean, width:Number, height:Number, autosize:Boolean)`
          );
          break;
        case "css":
          break;
        case "python":
          break;
        case "ruby":
          break;
        case "php":
          break;
        case "container":
          this.$emit(
            "updateInput",
            `${this.input}\n<div class="container">\n\n> put your markdown here to centered with a max in container\n</div>`
          );
          break;
        case "columns":
          this.$emit(
            "updateInput",
            `${this.input}\n<div class="row">\n<div class="col">\n\n# column left\n> this is a sample paragraph lorem ipsum in dolore etc</div>\n<div class="col">\n\n# column right\n> this is a sample paragraph lorem ipsum in dolore etc</div>\n</div>`
          );
          break;
      }
    }
  },
  watch: {}
};
</script>