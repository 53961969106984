<template>
  <div ref="containerAlphabet" width="100%" height="100%">
    <div
      class="picture_font"
      ref="picture"
      :style="'width:'+width+'px; height:'+height+'px; overflow:hidden;'"
    >
      <div
        v-for="obj in letters"
        :key="obj.letter"
        :style="'width:'+size+'px;  height:'+size+'px;'"
      >
        <img :src="obj.svg" :width="size" :height="size" />
      </div>
    </div>
  </div>
</template>
<script>
import A from "@/assets/images/alphabet/SVG/A.svg";
import B from "@/assets/images/alphabet/SVG/B.svg";
import C from "@/assets/images/alphabet/SVG/C.svg";
import D from "@/assets/images/alphabet/SVG/D.svg";
import E from "@/assets/images/alphabet/SVG/E.svg";
import F from "@/assets/images/alphabet/SVG/F.svg";
import G from "@/assets/images/alphabet/SVG/G.svg";
import H from "@/assets/images/alphabet/SVG/H.svg";
import I from "@/assets/images/alphabet/SVG/I.svg";
import J from "@/assets/images/alphabet/SVG/J.svg";
import K from "@/assets/images/alphabet/SVG/K.svg";
import L from "@/assets/images/alphabet/SVG/L.svg";
import M from "@/assets/images/alphabet/SVG/M.svg";
import N from "@/assets/images/alphabet/SVG/N.svg";
import O from "@/assets/images/alphabet/SVG/O.svg";
import P from "@/assets/images/alphabet/SVG/P.svg";
import Q from "@/assets/images/alphabet/SVG/Q.svg";
import R from "@/assets/images/alphabet/SVG/R.svg";
import S from "@/assets/images/alphabet/SVG/S.svg";
import T from "@/assets/images/alphabet/SVG/T.svg";
import U from "@/assets/images/alphabet/SVG/U.svg";
import V from "@/assets/images/alphabet/SVG/V.svg";
import W from "@/assets/images/alphabet/SVG/W.svg";
import X from "@/assets/images/alphabet/SVG/X.svg";
import Y from "@/assets/images/alphabet/SVG/Y.svg";
import Z from "@/assets/images/alphabet/SVG/Z.svg";
import _1 from "@/assets/images/alphabet/SVG/1.svg";
import _2 from "@/assets/images/alphabet/SVG/2.svg";
import _3 from "@/assets/images/alphabet/SVG/3.svg";
import _4 from "@/assets/images/alphabet/SVG/4.svg";
import _5 from "@/assets/images/alphabet/SVG/5.svg";
import _6 from "@/assets/images/alphabet/SVG/6.svg";
import _7 from "@/assets/images/alphabet/SVG/7.svg";
import _8 from "@/assets/images/alphabet/SVG/8.svg";
import _9 from "@/assets/images/alphabet/SVG/9.svg";
export default {
  props: {
    message: {
      type: String,
      required: true
    },
    ratio: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      A: A,
      B: B,
      C: C,
      D: D,
      E: E,
      F: F,
      G: G,
      H: H,
      I: I,
      J: J,
      K: K,
      L: L,
      M: M,
      N: N,
      O: O,
      P: P,
      Q: Q,
      R: R,
      S: S,
      T: T,
      U: U,
      V: V,
      W: W,
      X: X,
      Y: Y,
      Z: Z,
      1: _1,
      2: _2,
      3: _3,
      4: _4,
      5: _5,
      6: _6,
      7: _7,
      8: _8,
      9: _9,
      letters: [],
      size: 343,
      width: 343,
      height: 343
    };
  },
  mounted: function() {
    this.size = Math.round(
      this.$refs.containerAlphabet.getBoundingClientRect().width
    );

    this.width = this.size;
    this.height = this.size;
    if (typeof this.ratio !== "undefined") {
      //let parseRatio = this.ratio.split("/");
      this.height = this.width * this.ratio; // (this.width / parseRatio[1]) * parseRatio[0];
    }
    this.update();
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    message: function(newMessage) {
      this.update();
    }
  },
  methods: {
    update: function() {
      let truemessage = this.message.toUpperCase().replace(/[^A-Z1-9]/g, "");
      this.letters = [];
      let id = 0;
      for (let letter of truemessage) {
        this.letters.push({
          letter: "letter-" + id + "-" + letter,
          svg: this[letter]
        });
        id++;
      }
      if (truemessage.length <= 3) {
        this.size = Math.round(
          this.$refs.containerAlphabet.getBoundingClientRect().width
        );
      } else if (truemessage.length <= 6) {
        this.size =
          Math.round(
            this.$refs.containerAlphabet.getBoundingClientRect().width
          ) / 2;
      } else if (
        Math.round(this.$refs.containerAlphabet.getBoundingClientRect().width) /
          truemessage.length >
        this.width / 3
      ) {
        this.size =
          Math.round(
            this.$refs.containerAlphabet.getBoundingClientRect().width
          ) / truemessage.length;
      } else {
        this.size =
          Math.round(
            this.$refs.containerAlphabet.getBoundingClientRect().width
          ) / 3;
      }
    }
  }
};
</script>
<style scoped>
.picture_font {
  overflow: hidden;
  background-color: #e9ebf7;
}
.picture_font div {
  float: left;
}
.picture_font img {
  padding: 0;
  margin: 0;
}
</style>